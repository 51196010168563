import download from 'downloadjs';
import { JsonWebTokenError } from 'jsonwebtoken';
import queryString from 'querystring';
import {SLOT_STATES} from '../constants';
import Configuration from '../config';
import pl from 'date-fns/esm/locale/pl';

export async function login(credential) {
  const res = await fetch(`${Configuration.apiEndPoint}/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credential),
    mode: 'cors'
  });

  const json = await res.json();
  return {json, status: res.status};
}

export async function changePassword(credential, token) {
  const res = await fetch(`${Configuration.apiEndPoint}/users/change_pwd`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(credential),
    mode: 'cors'
  });

  const json = await res.json();
  return {json, status: res.status};
}

export async function signUpPlayer(signUpData) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(signUpData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function signUpPartner(signUpData) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(signUpData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPartnerProfile(signUpData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners?uid=${signUpData.uid}&email=${signUpData.email}&role=${signUpData.role}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPlayerProfile(signUpData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players?uid=${signUpData.uid}&email=${signUpData.email}&role=${signUpData.role}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function savePlayerProfile(profile, token) {
  try {
    profile.email = profile.user.email;
    const res = await fetch(`${Configuration.apiEndPoint}/players`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(profile)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function savePartnerProfile(profile, token) {
  try {
    const data = Object.assign({}, profile);
    delete data.businessName;
    delete data.iva;
    delete data.uid;
    delete data.user;
    const res = await fetch(`${Configuration.apiEndPoint}/partners`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteImageFile(token, file) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/images`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`},
      body: JSON.stringify({filenames: [file]})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPartnerFields(partnerId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/${partnerId}/fields`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPartnerServices(partnerId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/${partnerId}/services`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function savePartnerField(fieldData, token) {
  try {
    const payload = Object.assign({}, {
      sport: fieldData.sport,
      name: fieldData.name,
      description: fieldData.description,
      covered: fieldData.covered,
      terrain: fieldData.terrain,
      bookable: fieldData.bookable,
      bookingPolicy: fieldData.bookingPolicy,
      additionalCost: fieldData.additionalCost,
      termOfUse: fieldData.termOfUse,
      unbookableHours: fieldData.unbookableHours,
      affiliationsIds: fieldData.affiliationsIds
    });

    const res = await fetch(`${Configuration.apiEndPoint}/partners/fields`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updatePartnerField(fieldData, token) {
  try {
    const payload = Object.assign({}, {
      sport: fieldData.sport,
      name: fieldData.name,
      description: fieldData.description,
      covered: fieldData.covered,
      terrain: fieldData.terrain,
      bookable: fieldData.bookable,
      bookingPolicy: fieldData.bookingPolicy,
      additionalCost: fieldData.additionalCost,
      termOfUse: fieldData.termOfUse,
      unbookableHours: fieldData.unbookableHours,
      affiliationsIds: fieldData.affiliationsIds
    });
    
    const res = await fetch(`${Configuration.apiEndPoint}/partners/fields/${fieldData.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deletePartnerField(token, fieldId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/fields/${fieldId}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`}
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function savePartnerService(serviceData, token) {
  try {
    const payload = Object.assign({}, {
      briefDescription: serviceData.briefDescription,
      name: serviceData.name,
      description: serviceData.description,
      bookable: serviceData.bookable,
      bookingPolicy: serviceData.bookingPolicy,
      additionalCost: serviceData.additionalCost,
      termOfUse: serviceData.termOfUse,
      unbookableHours: serviceData.unbookableHours,
      affiliationsIds: serviceData.affiliationsIds
    });

    const res = await fetch(`${Configuration.apiEndPoint}/partners/services`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updatePartnerService(serviceData, token) {
  try {
    const payload = Object.assign({}, {
      briefDescription: serviceData.briefDescription,
      name: serviceData.name,
      description: serviceData.description,
      bookable: serviceData.bookable,
      bookingPolicy: serviceData.bookingPolicy,
      additionalCost: serviceData.additionalCost,
      termOfUse: serviceData.termOfUse,
      unbookableHours: serviceData.unbookableHours,
      affiliationsIds: serviceData.affiliationsIds
    });
    
    const res = await fetch(`${Configuration.apiEndPoint}/partners/services/${serviceData.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deletePartnerService(token, serviceId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/services/${serviceId}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`}
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteFieldImageFile(token, file, fieldId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/fields/${fieldId}/images`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`},
      body: JSON.stringify({filenames: [file]})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteServiceImageFile(token, file, serviceId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/services/${serviceId}/images`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`},
      body: JSON.stringify({filenames: [file]})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getFieldImage(fieldId, fileName, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/fields/${fieldId}/images?load=${fileName}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const blob = await res.blob();
    return {blob, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getFieldSlots(partnerId, token, fieldId, bookingPartner = false, targetDate = new Date(), searchRange = 'MONTH') {
  try {
    let startDatetime = null;
    let endDatetime = null;

    if (searchRange === 'WEEK') {
      // Nel calendario del player la visualizzazione copre al massimo la settimana
      // Carico allora la settimana della data passata
      // Calcolo data inizio settimana
      const day = targetDate.getDay();
      startDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate() + (day === 0 ? -6 : 1) - day);
      // Calcolo data fine settimana
      endDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate() + (day === 0 ? 0 : 7) - day, 23, 59);
    } else if (searchRange === 'DAY') {
      //Carico solo la giornata selezionata
      startDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate());
      endDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(), 23, 59);
    } else {
      // Nel calendario del Partner la visualizzazione copre al massimo il mese
      // Carico allora il mese della data passata
      // Calcolo data inizio mese
      startDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1 - 6);
      // Calcolo data fine mese
      endDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 6, 23, 59);
    }

    const res = await fetch(`${Configuration.apiEndPoint}/partners/${partnerId}/fields/slots?${queryString.stringify({fieldId, bookingPartner, startDatetime: startDatetime.toISOString(), endDatetime: endDatetime.toISOString()})}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getServiceSlots(partnerId, token, serviceId, bookingPartner = false, targetDate = new Date(), searchRange = 'MONTH') {
  try {
    let startDatetime = null;
    let endDatetime = null;

    if (searchRange === 'WEEK') {
      // Nel calendario del player la visualizzazione copre al massimo la settimana
      // Carico allora la settimana della data passata
      // Calcolo data inizio settimana
      const day = targetDate.getDay();
      startDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate() + (day === 0 ? -6 : 1) - day);
      // Calcolo data fine settimana
      endDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate() + (day === 0 ? 0 : 7) - day, 23, 59);
    } else {
      // Nel calendario del Partner la visualizzazione copre al massimo il mese
      // Carico allora il mese della data passata
      // Calcolo data inizio mese
      startDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1 - 6);
      // Calcolo data fine mese
      endDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 6, 23, 59);
    }

    const res = await fetch(`${Configuration.apiEndPoint}/partners/${partnerId}/services/slots?${queryString.stringify({serviceId, bookingPartner, startDatetime: startDatetime.toISOString(), endDatetime: endDatetime.toISOString()})}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function saveFieldSlot(slotData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/slots`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(slotData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updateFieldSlot(slotData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/slots/${slotData.slotId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(slotData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteFieldSlots(slotData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/slots`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(slotData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPartners(token, businessName = null) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners?businessName=${businessName}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getSlot(token, slotId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/slots/transactions/${slotId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteSlot(token, slotId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/slots/${slotId}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`},
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getBookings(token, targetDate = null, searchRange = 'MONTH', resourceType = 'field') {
  try {
    let startDatetime = null;
    let endDatetime = null;

    if (targetDate) {
      if (searchRange === 'WEEK') {
        // Nel calendario del player la visualizzazione copre al massimo la settimana
        // Carico allora la settimana della data passata
        // Calcolo data inizio  settimana
        const day = targetDate.getDay();
        startDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate() + (day === 0 ? -6 : 1) - day);
        // Calcolo data fine settimana
        endDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate() + (day === 0 ? 0 : 7) - day, 23, 59);
      } else {
        // Nel calendario del Partner la visualizzazione copre al massimo il mese
        // Carico allora il mese della data passata
        // Calcolo data inizio mese
        startDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1 - 6);
        // Calcolo data fine mese
        endDatetime = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 6, 23, 59);
      }
    }
    let res;
    if(resourceType == 'field')
      res = await fetch(`${Configuration.apiEndPoint}/bookings?${queryString.stringify({startDatetime: startDatetime ? startDatetime.toISOString() : null, endDatetime: endDatetime ? endDatetime.toISOString() : null})}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
    else
      res = await fetch(`${Configuration.apiEndPoint}/bookings/services?${queryString.stringify({startDatetime: startDatetime ? startDatetime.toISOString() : null, endDatetime: endDatetime ? endDatetime.toISOString() : null})}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
    let json = await res.json();
    if(resourceType === 'all'){
      const res2 = await fetch(`${Configuration.apiEndPoint}/bookings?${queryString.stringify({startDatetime: startDatetime ? startDatetime.toISOString() : null, endDatetime: endDatetime ? endDatetime.toISOString() : null})}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      json = [...await res2.json(), ...json];
      json.sort(function(a,b){
        return new Date(a.startDatetime) - new Date(b.startDatetime);
      });
    }
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function saveBooking(bookingData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/bookings`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(bookingData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function saveServiceBooking(bookingData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/bookings/services`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(bookingData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updateBooking(bookingData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/bookings/${bookingData.bookingId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(bookingData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updateServiceBooking(bookingData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/bookings/services/${bookingData.bookingId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(bookingData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updateUserBooking(bookingData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/user_bookings/${bookingData.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(bookingData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteBooking(bookingId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/bookings/${bookingId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteServiceBooking(bookingId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/bookings/services/${bookingId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteUserBooking(userBookingId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/user_bookings/${userBookingId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteServiceUserBooking(userBookingId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/user_bookings/services/${userBookingId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deletePlayer(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function sendResetPwd(email) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/users/send_reset_pwd`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(email)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function verifyEmail(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/verify_email/${token}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function confirmChangeEmail(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/change_email/${token}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function resetPassword(data, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/users/reset_pwd/${token}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function addToBookingQueue(slotId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/booking_queues`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({slotId})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function topUpWallet(topUpdata, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/user/wallet`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(topUpdata)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function purchaseVideo(slots, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/slots/video`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({slots})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function purchasePartnerVideo(videoId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/video_partners/buy`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({videoId})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getVideo(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/new_videos`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getVideoStreaming(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/new_videos_in_streaming`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    json.sort(function(a,b){
      return new Date(b.startDatetime) - new Date(a.startDatetime);
    });
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function printReport(filters, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/reports?start=${filters.start || ''}&end=${filters.end || ''}&field=${filters.field || ''}&service=${filters.service || ''}&bookings_type=${filters.bookingsType || SLOT_STATES.available}&email=${filters.email || ''}&surname=${filters.surname || ''}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Disposition': 'attachment; filename=report.xls'
      }
    });
    if (res.status === 200) {
      const blob = await res.blob();
      download(blob, 'report.xls');
      return {status: res.status};
    } else {
      const json = await res.json();
      return {json, status: res.status};
    }
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function archiveBooking(bookingId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/bookings/${bookingId}/archive`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function reportAllPlayers(token) {
  try {
    const fileName = `ReplayerListaPlayer_${new Date().toISOString().slice(0,10).replace(/-/g,"")}.xls`;
    const res = await fetch(`${Configuration.apiEndPoint}/players/all`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Disposition': 'attachment; filename=report.xls'
      }
    });
    if (res.status === 200) {
      const blob = await res.blob();
      download(blob, fileName);
    }

    return {status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function addVideoToPlayerArea(slotId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/add_video/from_slot/${slotId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function addPartnerVideoToPlayerArea(videoId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/add_partner_video/${videoId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function requestVideo(slotId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/request_video/${slotId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPlayerPermanentVideos(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/permanent_videos`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPlayerPermanentPartnerVideos(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/permanent_partner_videos`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function confirmPlayerAffiliation(affiliationId, playerId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations/${affiliationId}/players_email?playerId=${playerId}&token=${token}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPlayerPlaylists(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/playlist_videos`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deletePlayerPermanentVideo(videoId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/delete_permanent_videos/${videoId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deletePlayerPermanentPartnerVideo(videoId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/user_video_partners/${videoId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}


export async function deletePlaylist(playlistId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/delete_playlist/${playlistId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

// videoData: {name, description}
export async function modifyPlayerPermanentVideo(videoId, videoData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/change_info_permanent_videos/${videoId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(videoData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

// videoData: {name, description}
export async function modifyPlaylist(playlistId, videoData, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/change_info_playlist/${playlistId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(videoData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

// videoSegment: {name, description, start, duration*, end*} (*: solo un campo dei due, o duration o end)
export async function cutPlayerPermanentVideo(videoId, videoSegment, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/cut_videos/${videoId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(videoSegment)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function createPlaylist(videoId, videoSegment, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/create_playlist/${videoId}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(videoSegment)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function reportTransactions(filters, token) {
  try {
    const fileName = `ReplayerTransazioni_${new Date().toISOString().slice(0,10).replace(/-/g,"")}.xls`;
    const res = await fetch(`${Configuration.apiEndPoint}/players/transactions?start=${filters.start ? new Date(filters.start).toISOString().slice(0,10) : ''}&end=${filters.end ? new Date(filters.end).toISOString().slice(0,10) : ''}&email=${filters.email || ''}&surname=${filters.surname || ''}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Disposition': 'attachment; filename=report.xls'
      }
    });
    if (res.status === 200) {
      const blob = await res.blob();
      download(blob, fileName);
    }

    return {status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function adminReportSubscriptions(filters, token) {
  try {
    const fileName = `ReplayerAbbonamenti_${new Date().toISOString().slice(0,10).replace(/-/g,"")}.xls`;
    const res = await fetch(`${Configuration.apiEndPoint}/subscriptions/report?start=${filters.start ? new Date(filters.start).toISOString().slice(0,10) : ''}&end=${filters.end ? new Date(filters.end).toISOString().slice(0,10) : ''}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Disposition': 'attachment; filename=report.xls'
      }
    });
    if (res.status === 200) {
      const blob = await res.blob();
      download(blob, fileName);
    }

    return {status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function adminReportUsage(filters, token) {
  try {
    const fileName = `ReplayerUtilizzo_${filters.reportType}_${new Date().toISOString().slice(0,10).replace(/-/g,"")}.xls`;
    const res = await fetch(`${Configuration.apiEndPoint}/admin/usage_report?start=${filters.start ? new Date(filters.start).toISOString().slice(0,10) : ''}&end=${filters.end ? new Date(filters.end).toISOString().slice(0,10) : ''}&reportType=${filters.reportType}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Disposition': 'attachment; filename=report.xls'
      }
    });
    if (res.status === 200) {
      const blob = await res.blob();
      download(blob, fileName);
    }

    return {status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function reportBookings(filters, token) {
  try {
    const fileName = `ReplayerPrenotazioni_${new Date().toISOString().slice(0, 10).replace(/-/g, '')}.xls`;
    const res = await fetch(`${Configuration.apiEndPoint}/bookings/archived_bookings?start=${filters.start ? new Date(filters.start).toISOString().slice(0, 10) : ''}&end=${filters.end ? new Date(filters.end).toISOString().slice(0, 10) : ''}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Disposition': 'attachment; filename=report.xls'
      }
    });
    if (res.status === 200) {
      const blob = await res.blob();
      download(blob, fileName);
    }

    return {status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function reportSubscriptions(filters, token) {
  try {
    const fileName = `ReplayerAbbonamenti_${new Date().toISOString().slice(0, 10).replace(/-/g, '')}.xls`;
    const res = await fetch(`${Configuration.apiEndPoint}/player/archived_subscriptions?start=${filters.start ? new Date(filters.start).toISOString().slice(0, 10) : ''}&end=${filters.end ? new Date(filters.end).toISOString().slice(0, 10) : ''}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Disposition': 'attachment; filename=report.xls'
      }
    });
    if (res.status === 200) {
      const blob = await res.blob();
      download(blob, fileName);
    }

    return {status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function partnerReportPlayers(token) {
  try {
    const fileName = `ReplayerPlayers_${new Date().toISOString().slice(0,10).replace(/-/g,"")}.xls`;
    const res = await fetch(`${Configuration.apiEndPoint}/partners/players_report`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Disposition': 'attachment; filename=report.xls'
      }
    });
    if (res.status === 200) {
      const blob = await res.blob();
      download(blob, fileName);
    }

    return {status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getFavouritePartners(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/get_favourite_partners`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function addPartnerToFavourite(uid, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/add_favourite_partner`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({uid})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function removePartnerFromFavourite(uid, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/remove_favourite_partner/${uid}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`}
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPlayers(surname, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/list?surname=${surname}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function playerGetPlayers(nickname, name, surname, affiliated, slotId, partnerId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/players_list?nickname=${nickname}&name=${name}&surname=${surname}&affiliated=${affiliated}&slotId=${slotId}&partnerId=${partnerId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function partnerGetPlayers(nickname, name, surname, affiliated, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/players_list?nickname=${nickname}&name=${name}&surname=${surname}&affiliated=${affiliated}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function permanentVideosSharing(videoId, sharing, playlistId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/permanent_videos/sharing`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({videoId, sharing, playlistId})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getSharedVideo(sharingId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/permanent_videos/sharing/${sharingId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function createPartner(partner, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(partner)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

// Subscriptions

export async function getPlayerSubscriptions(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/player/subscriptions`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function setPlayerSubscription(data, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/player/subscriptions`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function changePlayerSubscription(subscription, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/player/subscription/${subscription.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(subscription)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function changeStorageType(newStorageType, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/storage_type`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(newStorageType)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function renewPlayerSubscription(subscriptionId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/player/subscription/${subscriptionId}/renew`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getRtvVideos(token, partnerUid, rtvChannelId = "all") {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/${partnerUid}/rtv/${rtvChannelId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    json.sort(function(a,b){
      return new Date(b.timestamp) - new Date(a.timestamp);
    });
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getRtvPartnerVideos(token, partnerUid, rtvChannelId = "all") {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/${partnerUid}/rtv_video_partner/${rtvChannelId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    json.sort(function(a,b){
      return new Date(b.timestamp) - new Date(a.timestamp);
    });
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getRtvVideosSoon(token, partnerUid, rtvChannelId = "all") {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/${partnerUid}/rtv_soon/${rtvChannelId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteRtvVideo(slotId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/rtv_video/${slotId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getVideosStore(token, partnerId, fieldId = null, serviceId = null, fromDatetime = null, toDatetime = null) {
  try {
    const params = Object.fromEntries(Object.entries({fieldId, serviceId, fromDatetime, toDatetime}).filter(([_, v]) => v !== null));

    const res = await fetch(`${Configuration.apiEndPoint}/store_videos/${partnerId}?${queryString.stringify(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getVideosStoreSoon(token, partnerId, fieldId = null, serviceId = null, fromDatetime = null, toDatetime = null) {
  try {
    const params = Object.fromEntries(Object.entries({fieldId, serviceId, fromDatetime, toDatetime}).filter(([_, v]) => v !== null));

    const res = await fetch(`${Configuration.apiEndPoint}/store_videos_soon/${partnerId}?${queryString.stringify(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getVideosStorePartnerVideo(token, partnerId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/store_videos/video_partner/${partnerId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updatePartnerByAdmin(partner, token) {
  try {
    const payload = Object.assign({}, {
      rtvKeepVideos: partner.rtvKeepVideos
    });

    const res = await fetch(`${Configuration.apiEndPoint}/admin/partners/${partner.uid}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updatePartnerVideoCostByAdmin(partner, token) {
  try {
    const payload = Object.assign({}, {
      videoPartnerCost: partner.videoPartnerCost
    });

    const res = await fetch(`${Configuration.apiEndPoint}/admin/partners/video_partner/${partner.uid}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}


export async function changeStoragePrices(values, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/storage_prices`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updatePartnerFieldByAdmin(field, token) {
  try {
    const payload = Object.assign({}, {
      rtv: field.rtv,
      recording: field.recording,
      videoHourPrice: Number(field.videoHourPrice) * 100,
      payment: field.payment,
      notifications: field.notifications
    });

    const res = await fetch(`${Configuration.apiEndPoint}/admin/partners/fields/${field.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updatePartnerServiceByAdmin(service, token) {
  try {
    const payload = Object.assign({}, {
      rtv: service.rtv,
      recording: service.recording,
      videoHourPrice: Number(service.videoHourPrice) * 100,
      payment: service.payment
    });

    const res = await fetch(`${Configuration.apiEndPoint}/admin/partners/services/${service.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getStoragePrices(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/storage_prices`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}


export async function updateUserLanguage(language, token) {
  try {
    const payload = Object.assign({}, {
      language: language
    });
    const res = await fetch(`${Configuration.apiEndPoint}/users/language`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getEvents(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/events`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getUserTags(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/tags/user_tags`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function savePartnerEvent(eventData, token) {
  try {
    const payload = Object.assign({}, {
      title: eventData.title,
      description: eventData.description,
      startDatetime: eventData.startDatetime,
      endDatetime: eventData.endDatetime,
      rtvChannelId: Number(eventData.rtvChannelId)
    });

    const res = await fetch(`${Configuration.apiEndPoint}/partners/events`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function saveUserTag(userTag, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/tags/user_tag`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(userTag)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updatePartnerEvent(eventData, token) {
  try {
    const payload = Object.assign({}, {
      title: eventData.title,
      description: eventData.description,
      startDatetime: eventData.startDatetime,
      endDatetime: eventData.endDatetime,
      rtvChannelId: Number(eventData.rtvChannelId)
    });

    const res = await fetch(`${Configuration.apiEndPoint}/partners/events/${eventData.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updateUserTag(userTag, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/tags/user_tag/${userTag.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(userTag)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPartnerEvents(partnerId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/${partnerId}/events`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deletePartnerEvent(token, eventId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/events/${eventId}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`}
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteUserTag(token, userTagId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/tags/user_tag/${userTagId}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`}
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteEventImageFile(token, file, eventId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/events/${eventId}/images`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`},
      body: JSON.stringify({filenames: [file]})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getUserVideos(tags, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/tags/user_videos?${queryString.stringify({tags})}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getSponsors(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/sponsors`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getUserVideoTags(videoId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/tags/user_video_tags/${videoId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function saveSponsor(sponsor, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/sponsors`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(sponsor)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function saveUserVideoTag(userVideoTag, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/tags/user_video_tag`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(userVideoTag)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updateSponsor(sponsor, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/sponsors/${sponsor.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(sponsor)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updateUserVideoTag(userVideoTag, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/tags/user_video_tag/${userVideoTag.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(userVideoTag)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteSponsor(token, sponsorId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/sponsors/${sponsorId}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`}
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteUserVideoTag(token, userVideoTagId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/tags/user_video_tag/${userVideoTagId}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`}
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteSponsorImageFile(token, file, sponsorId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/sponsors/${sponsorId}/image`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`},
      body: JSON.stringify({filenames: [file]})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getSponsor(token, sponsorId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/sponsors/${sponsorId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPartnerRtvChannels(partnerId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/${partnerId}/rtv_channels`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    let json = await res.json();
    json = json.map(r => {
      r.rtvPrice /= 100;
      r.rtvPriceMembership /= 100;
      r.rtvPriceEvent /= 100;
      return r;
    })
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function createPartnerRtvChannel(rtvChannel, token) {
  try {
    const payload = { ...rtvChannel };
    payload.rtvPrice *= 100;
    payload.rtvPriceMembership *= 100;
    payload.rtvPriceEvent *= 100;

    const res = await fetch(`${Configuration.apiEndPoint}/admin/partners/rtv_channels`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function updatePartnerRtvChannel(rtvChannel, token) {
  try {
    const payload = { ...rtvChannel };
    payload.rtvPrice *= 100;
    payload.rtvPriceMembership *= 100;
    payload.rtvPriceEvent *= 100;

    const res = await fetch(`${Configuration.apiEndPoint}/admin/partners/rtv_channels/${rtvChannel.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteRtvChannel(rtvChannelId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/partners/rtv_channels/${rtvChannelId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteRtvChannelImageFile(token, file, rtvChannelId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/rtv_channels/${rtvChannelId}/images`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`},
      body: JSON.stringify({filenames: [file]})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function saveXml(data, token) {
  const formData = new FormData();
  formData.append('xml', data.selectedFile);
  formData.append('fieldId', data.fieldId);
  formData.append('xmlType', data.xmlType);

  try {
    const res = await fetch(`${Configuration.apiEndPoint}/xml`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: formData,
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function savePartnerVideoXml(data, token) {
  const formData = new FormData();
  formData.append('xml', data.selectedFile);
  formData.append('partnerId', data.partnerId);
  formData.append('xmlType', data.xmlType);

  try {
    const res = await fetch(`${Configuration.apiEndPoint}/xml/partner`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: formData,
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function createCamera(values, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/camera`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function modifyCamera(values, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/camera/${values.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteCamera(cameraId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/camera/${cameraId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getFavouriteRtvChannels(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/get_favourite_rtv_channels`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function addRtvChannelToFavourite(rtv_channel_id, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/add_favourite_rtv_channel`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({rtv_channel_id})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function removeRtvChannelFromFavourite(rtv_channel_id, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/remove_favourite_rtv_channel/${rtv_channel_id}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`}
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function createPartnerVideo(data, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/video_partners`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function editPartnerVideo(videoId, data, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/video_partners/${videoId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getPartnerVideos(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/video_partners/all`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deletePartnerVideo(videoId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/video_partners/${videoId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteVideoImageFile(token, file, videoPartnerId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/video_partners/${videoPartnerId}/images`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`},
      body: JSON.stringify({filenames: [file]})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function changePartnerPermissions(values, partnerId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/change_partner_permissions/${partnerId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function postUsageData(usageType, partnerId, rtvChannelId, slotId, streaming, contextPage, videoPartnerTitle, videoPartnerName, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/usage_data`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({usageType, partnerId, rtvChannelId, slotId, streaming, contextPage, videoPartnerTitle, videoPartnerName})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function adminCreateCategory(values, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/categories`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getRtvCategories(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/categories`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function adminEditCategory(values, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/categories/${values.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function adminAddOrRemoveChannelFromCategory(rtvChannelId, add, categoryId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/categories/channel_to_category/${categoryId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({rtvChannelId, add, categoryId})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function adminDeleteCategory(categoryId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/categories/${categoryId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function deleteCategoryImageFile(token, file, categoryId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/admin/categories/image/${categoryId}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`},
      body: JSON.stringify({filenames: [file]})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function playerGetRtvCategories(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/categories`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function partnerGetRtvCategories(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/categories`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function downloadNvrTracking(filters, token) {
  const downloadFile = (url, filename, slotId) => {
    if (slotId === null){
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
      })
      .catch(console.error);
    }
    else{
      fetch(url)
        .then(response => response.blob())
        .then(blob => blob.text())  // Convert the Blob into a string
        .then(csvString => {
          // Parse the CSV string into rows
          const rows = csvString.split('\n').map(row => row.split(','));

          // Filter the rows based on the value of a column
          const filteredRows = rows.filter(row => String(row[0]).trim() === String(slotId).trim() || String(row[0]).trim() === "slot_id");

          // Convert the filtered rows back into a CSV string
          const filteredCsvString = filteredRows.map(row => row.join(',')).join('\n');

          // Convert the CSV string back into a Blob
          const filteredBlob = new Blob([filteredCsvString], {type: 'text/csv'});

          // Download the filtered CSV
          const link = document.createElement("a");
          link.href = URL.createObjectURL(filteredBlob);
          link.download = filename;
          link.click();
      })
      .catch(console.error);
    }
  };

  try {
    const fileName = filters.slotId === null ? `ReplayerNvrTracking_${new Date(filters.date).toISOString().slice(0,10)}.csv` : `ReplayerNvrTracking_${new Date(filters.date).toISOString().slice(0,10)}_id${filters.slotId}.csv`;
    const res = await fetch(`${Configuration.apiEndPoint}/admin/nvr_report?date=${filters.date ? new Date(filters.date).toISOString().slice(0,10) : ''}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    if (res.status === 200) {
      const json = await res.json();
      downloadFile(json, fileName, filters.slotId);
    }

    return {status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function partnerCreateFixedCost(values, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/fields/costs`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function partnerDeleteFixedCost(id, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/partners/fields/costs/${id}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`},
      body: JSON.stringify({id: id})
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function saveEditedPlayers(bookingData, bookingId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/bookings/players/${bookingId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(bookingData)
    });
    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}};
  }
}

export async function getLiveVideos(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/streaming_videos`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function getComingSoonVideos(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/players/streaming_soon_videos`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function partnerGetAffiliations(token, partnerId) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations/partners/${partnerId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function editAffiliation(affiliation, token) {
  var data = {...affiliation};
  delete data.fields;
  delete data.services;
  delete data.players;
  delete data.type;
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations/${affiliation.affiliationId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function deleteAffiliation(affiliationId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations/${affiliationId}`, {
      method: 'DELETE',
      headers: {Authorization: `Bearer ${token}`}
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function createAffiliation(affiliation, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(affiliation)
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function activateDeactivateAffiliation(affiliationId, active, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations/activate/${affiliationId}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({active})
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function getAffiliatedPlayers(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations/players`, {
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`}
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function deletePlayerFromAffiliation(playerId, affiliationId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations/players`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({'playersAffiliations': [{playerId, affiliationId}]})
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function editAffiliationPlayer(affiliationId, playerId, active, medicalCertificateTimestamp, payed, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations/players`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({'playersAffiliations': [{affiliationId, playerId, active, medicalCertificateTimestamp, payed}]})
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function getPlayerAffiliations(token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations/get_player_affiliations`, {
      method: 'GET',
      headers: {Authorization: `Bearer ${token}`}
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function requestAffiliation(affiliationId, playerId, playerOnlinePayment, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations/players`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({"playersAffiliations":[{affiliationId, playerId, playerOnlinePayment}]})
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}

export async function renewPlayerAffiliation(playerId, affiliationId, token) {
  try {
    const res = await fetch(`${Configuration.apiEndPoint}/affiliations/renew`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({"playersAffiliations":[{affiliationId, playerId}]})
    });

    const json = await res.json();
    return {json, status: res.status};
  } catch (error) {
    return {status: -1, json: {error: error.message}}
  }
}