/* eslint complexity: ["error", 60] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Container, Fade, Carousel, Form, Col, Card, Row} from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog';
import {Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import CommonMetaTag from './common-meta-tag';
import Configuration from '../config';
import terrainIcon from '../images/terreno_campo.svg';
import swimmingPoolIcon from '../images/terreno_piscina.svg';
import restaurant from '../images/ristorante.svg';
import bar from '../images/bar_icon.svg';
import accessibility from '../images/disabled_icon.svg';
import showers from '../images/shower_icon.svg';
import shop from '../images/negozio.svg';
import rtvIcon from '../images/replayer_tv_red.svg';
import parking from '../images/parking_icon.svg';
import petFriendly from '../images/pets_icon.svg';
import lockerRooms from '../images/locker_room_icon.svg';
import tournaments from '../images/tournaments_icon.svg';
import picnic from '../images/picnic_area_icon.svg';
import pointerIcon from '../images/pointer_icon.svg';
import envelopeIcon from '../images/envelope_icon.svg';
import phoneIcon from '../images/phone_icon.svg';
import indoorIcon from '../images/indoor_icon.svg';
import outdoorIcon from '../images/outdoor_icon.svg';
import camIcon from '../images/cam_icon.svg';
import checkIcon from '../images/check_icon.svg';
import clockIcon from '../images/clock_icon.svg';
import relax from '../images/relax_icon.svg';
import onOffIcon from '../images/on-off_icon.svg';
import offIcon from '../images/off_icon.svg';
import calendarIconRed from '../images/calendar_icon_red.svg';
import replayerTvRed from '../images/replayer_tv_red.svg';
import replayerTvIcon from '../images/replayer_tv_icon.svg';
import youtubePosIcon from '../images/youtube_pos_icon.svg';
import facebookPosIcon from '../images/facebook_pos_icon.svg';
import instagramPosIcon from '../images/instagram_pos_icon.svg';
import {subscriptionStatuses, SPORTS, subscriptionKind, affiliationType} from '../constants';
import PlayerNewSubscription from './player-new-subscription';
import PlayerNewEventSubscription from './player-new-event-subscription';
import PlayerInsufficientFunds from './player-insufficient-funds';
import PlayerChooseRtvChannel from './player-choose-rtv-channel';

const ShowPartnerSpecialist = ({
  profile,
  partner,
  isFavourite,
  token,
  fields,
  services,
  showCalendar,
  showServiceCalendar,
  showDetail,
  toggleFavourite,
  showReplayerTV,
  showVideoStore,
  showServiceVideoStore,
  rtvMembershipNotAllowed,
  addToCoachNotAllowed,
  subscriptions,
  events,
  chooseReplayerTV,
  affiliations,
  requestAffiliation
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  if (partner === null) {
    return null;
  }

  let dialog = null;
  const addToGroupConfirmNoPayment = (affiliation, type, message) => {
    dialog.show({
      title: t('MEMBERSHIP_REQUEST'),
      body: <Fragment>
              <Form.Label>
                <strong>{t('MEMBERSHIP_PROFILE', {membership: type})}</strong>
              </Form.Label>
              <Form.Label>
                {message}
              </Form.Label>
              <Form.Label>
                {t('MEMBERSHIP_SEND')}
              </Form.Label>
              <Form.Label>
                {t('MEMBERSHIP_CONFIRM')}
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('SEND'),
          requestAffiliation(affiliation.affiliationId, profile.uid, false, affiliation.name, partner.name),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const addToGroupConfirmPayment = (affiliation) => () => {
    dialog.show({
      title: t('AFFILIATION_PAYMENT'),
      body: <Fragment>
              <Form.Label>
                {t('AFFILIATION_PAYMENT_DESC', {partner: partner.name, affiliation: affiliation.name, price: affiliation.price.toFixed(2)})}
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('YES_PAY'),
          requestAffiliation(affiliation.affiliationId, profile.uid, true, affiliation.name, partner.name),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const addToGroupWithPayment = (affiliation, type, message) => () => {
    dialog.show({
      title: affiliation.name,
      body: <Fragment>
              <Form.Label>
                <strong>{t('AFFILIATION_PROFILE_PAYMENT')}</strong>
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('REQUEST_AFFILIATION_PARTNER'),
          () => {
            setTimeout(() => {
              addToGroupConfirmNoPayment(affiliation, type, message);
            }, 200);
          },
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('PAY_AFFILIATION', {price: affiliation.price.toFixed(2)}),
          addToGroupConfirmPayment(affiliation),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const renewToGroupConfirmNoPayment = (affiliation) => {
    dialog.show({
      title: t('RENEW_AFFILIATION'),
      body: <Fragment>
              <Form.Label>
                <strong>{t('MEMBERSHIP_PROFILE', {membership: affiliation.name})}</strong>
              </Form.Label>
              <Form.Label>
                {t('RENEW_AFFILIATION_DESC', {partnerName: partner.name, expiryDate: new Date(affiliation.expiryTimestamp).toLocaleDateString()})}
              </Form.Label>
              <Form.Label>
                {t('RENEW_AFFILIATION_DESC_TWO')}
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('SEND'),
          () => console.log('renew'),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const renewToGroupConfirmPayment = (affiliation) => () => {
    dialog.show({
      title: t('RENEW_AFFILIATION'),
      body: <Fragment>
              <Form.Label>
                {t('AFFILIATION_PAYMENT_DESC_RENEW', {partner: partner.name, affiliation: affiliation.name, price: affiliation.price.toFixed(2)})}
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('YES_PAY'),
          () => console.log('renew'),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const renewToGroupWithPayment = (affiliation) => () => {
    dialog.show({
      title: affiliation.name,
      body: <Fragment>
              <Form.Label>
                <strong>{t('AFFILIATION_PROFILE_PAYMENT_RENEW')}</strong>
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('REQUEST_AFFILIATION_PARTNER'),
          () => {
            setTimeout(() => {
              renewToGroupConfirmNoPayment(affiliation);
            }, 200);
          },
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('PAY_AFFILIATION', {price: affiliation.price.toFixed(2)}),
          renewToGroupConfirmPayment(affiliation),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const membershipAlreadyActive = type => () => {
    dialog.show({
      title: t('MEMBERSHIP_ACTIVE'),
      body: <Fragment>
              <Form.Label>
                <strong>{t('MEMBERSHIP_PROFILE', {membership: type})}</strong>
              </Form.Label>
              <Form.Label>
                {t('MEMBERSHIP_ALREADY_ACTIVE')}
              </Form.Label>
              <Form.Label>
                {t('MEMBERSHIP_CANCEL')}
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('OK'),
          null,
          'btn btn-secondary'
        )],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const membershipStandBy = type => () => {
    dialog.show({
      title: t('MEMBERSHIP_STAND_BY'),
      body: <Fragment>
              <Form.Label>
                <strong>{t('MEMBERSHIP_PROFILE', {membership: type})}</strong>
              </Form.Label>
              <Form.Label>
                {t('MEMBERSHIP_STAND_BY_DESC')}
              </Form.Label>
              <Form.Label>
                {t('MEMBERSHIP_CONFIRM')}
              </Form.Label>
              <Form.Label>
                {t('MEMBERSHIP_CONTACT')}
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('OK'),
          null,
          'btn btn-secondary'
        )],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const getSubscriptionStatus = partner => {
    if (subscriptions) {
      const unlimitedSubscription = subscriptions.subscriptions.find(s => new Date(s.expiry) >= new Date() && s.kind === subscriptionKind.rtvUnlimited);
      if (unlimitedSubscription) {
        return {status: subscriptionStatuses.unlimited};
      }

      const sub = subscriptions.subscriptions.find(s => s.partner === partner.uid);
      if (sub && new Date(sub.expiry) >= new Date()) {
        return {status: subscriptionStatuses.valid};
      }


      if (sub && new Date(sub.expiry) < new Date()) {
        return {status: subscriptionStatuses.expired, subscriptionId: sub.id};
      }
    }

    return {status: subscriptionStatuses.noSubscription};
  };

  const getSubscriptionStatusEvent = (partnerId, rtvChannelId) => {
    if (subscriptions) {
      const sub = subscriptions.subscriptions.find(s => s.partner === partnerId && s.rtv_channel_id === rtvChannelId);
      if (sub && new Date(sub.expiry) >= new Date()) {
        return {status: subscriptionStatuses.valid};
      }

      const unlimitedSubscription = subscriptions.subscriptions.find(s => new Date(s.expiry) >= new Date() && s.kind === subscriptionKind.rtvUnlimited);
      if (unlimitedSubscription) {
        return {status: subscriptionStatuses.unlimited};
      }
    }

    return {status: subscriptionStatuses.canRequestEvent};
  };

  const renderMembershipButton = affiliation => {
    if (affiliation.type === affiliationType.partner) {
      const activePartnerAffiliation = affiliations.find(m => m.affiliationId === affiliation.affiliationId);
      if (activePartnerAffiliation) {
        if (activePartnerAffiliation.renewable && !activePartnerAffiliation.renewAlreadyRequested && activePartnerAffiliation.active && (activePartnerAffiliation.expiryTimestamp !== null && new Date(activePartnerAffiliation.expiryTimestamp) >= new Date())){
          return (<Button variant="primary" style={{marginRight: 10, marginBottom: 20, background:"#00D6D2"}} onClick={affiliation.onlinePayment ? renewToGroupWithPayment(affiliation) : () => renewToGroupConfirmNoPayment(affiliation)}><img src={`${checkIcon}`} style={{width:"25px"}}/>{t('RENEW') + ' ' + t('PROFILE') + ' ' + affiliation.name}</Button>);
        }
        else if (activePartnerAffiliation.active && (activePartnerAffiliation.expiryTimestamp === null || (activePartnerAffiliation.expiryTimestamp !== null && new Date(activePartnerAffiliation.expiryTimestamp) >= new Date()))) {
          return (<Button variant="primary" style={{marginRight: 10, marginBottom: 20, background:"#00D6D2"}} onClick={membershipAlreadyActive(affiliation.name)}><img src={`${checkIcon}`} style={{width:"25px"}}/> {t('PROFILE') + ' ' + affiliation.name + ' ' + t('ACTIVE_TWO')}</Button>);
        }
        else if (activePartnerAffiliation.active === false) {
          return (<Button variant="dark" style={{marginRight: 10, marginBottom: 20}}><img src={`${offIcon}`} style={{width:"25px"}}/> {t(t('PROFILE') + ' ' + affiliation.name + ' ' + t('NONACTIVE_TWO'))}</Button>);
        }
        else if (activePartnerAffiliation.renewable && !activePartnerAffiliation.renewAlreadyRequested && activePartnerAffiliation.expiryTimestamp !== null && new Date(activePartnerAffiliation.expiryTimestamp) < new Date()) {
          return (<Button variant="primary" style={{marginRight: 10, marginBottom: 20, background:"#00c1bd", opacity: 0.65}} onClick={affiliation.onlinePayment ? renewToGroupWithPayment(affiliation) : () => renewToGroupConfirmNoPayment(affiliation)}><img src={`${onOffIcon}`} style={{width:"25px"}}/>{t('RENEW') + ' ' + t('PROFILE') + ' ' + affiliation.name}</Button>);
        }
        else if (activePartnerAffiliation.active === null || activePartnerAffiliation.renewAlreadyRequested) {
          return (<Button variant="primary" style={{marginRight: 10, marginBottom: 20, background:"#00494c"}} onClick={membershipStandBy(affiliation.name)}><img src={`${clockIcon}`} style={{width:"25px"}}/>{t('PROFILE') + ' ' + affiliation.name + ' standby'}</Button>);
        }
      }
      else return (<Button variant="primary" style={{marginRight: 10, marginBottom: 20, background:"#00c1bd", opacity: 0.65}} onClick={affiliation.onlinePayment ? addToGroupWithPayment(affiliation, affiliation.name, t('MEMBERSHIP_DESC', {membership: affiliation.name, partnerName: partner.name})) : () => addToGroupConfirmNoPayment(affiliation, affiliation.name, t('MEMBERSHIP_DESC', {membership: affiliation.name, partnerName: partner.name}))}><img src={`${onOffIcon}`} style={{width:"25px"}}/>{t('REQUEST_PROFILE') + ' ' + affiliation.name}</Button>);
    }

    const activeCoachMembership = affiliations.find(m => m.affiliation.type === affiliationType.coach && m.affiliation.partnerId === partner.uid && m.affiliation.active);
    const s = getSubscriptionStatus(partner);

    if (affiliation.type === affiliationType.coach && affiliation.active) {
      if (activeCoachMembership) {
        if (activeCoachMembership.active) {
          return (<Button variant="primary" style={{marginRight: 10, marginBottom: 20, background:"#00D6D2"}} onClick={membershipAlreadyActive('Coach')}><img src={`${checkIcon}`} style={{width:"25px"}}/> {t('MEMBERSHIP_COACH_ACTIVE')}</Button>);
        }
        else if (activeCoachMembership.active === false) {
          return (<Button variant="dark" style={{marginRight: 10, marginBottom: 20}}><img src={`${offIcon}`} style={{width:"25px"}}/> {t(t('PROFILE') + ' ' + affiliation.name + ' ' + t('NONACTIVE_TWO'))}</Button>);
        }
        else return (<Button variant="primary" style={{marginRight: 10, marginBottom: 20, background:"#00494c"}} onClick={membershipStandBy('Coach')}><img src={`${clockIcon}`} style={{width:"25px"}}/> {t('MEMBERSHIP_COACH_STAND_BY')}</Button>);
      }
      else {
        if (s.status === subscriptionStatuses.valid || s.status === subscriptionStatuses.unlimited) {
          return (<Button variant="primary" style={{marginRight: 10, marginBottom: 20, background:"#00c1bd", opacity: 0.65}} onClick={() => addToGroupConfirmNoPayment(affiliation, 'Coach', t('MEMBERSHIP_COACH_DESC', {partnerName: partner.name}))}><img src={`${onOffIcon}`} style={{width:"25px"}}/> {t('MEMBERSHIP_COACH')}</Button>);
        }
        else
        {
          return (<Button variant="dark" style={{marginRight: 10, marginBottom: 20}} onClick={() => addToCoachNotAllowed()}><img src={`${offIcon}`} style={{width:"25px"}}/> {t('MEMBERSHIP_COACH')}</Button>);
        }
      }
    }
    
    if (affiliation.type === affiliationType.rtv) {
      const activeRtvMembership = affiliations.find(m =>  m.affiliation.type === affiliationType.rtv && m.affiliation.rtvChannelId === affiliation.rtvChannelId);
      const rtvChannel = partner.rtvChannels.find(c => c.id === affiliation.rtvChannelId);
      if (activeRtvMembership) {
        if (activeRtvMembership.active) {
          return (<Button variant="primary" style={{marginRight: 10, marginBottom: 20, background:"#00D6D2"}} onClick={membershipAlreadyActive('ReplayerTV')}><img src={`${checkIcon}`} style={{width:"25px"}}/> {t('MEMBERSHIP_RTV_ACTIVE')} {rtvChannel ? rtvChannel.name : null}</Button>);
        }
        else if (activeRtvMembership.active === false) {
          return (<Button variant="dark" style={{marginRight: 10, marginBottom: 20}}><img src={`${offIcon}`} style={{width:"25px"}}/> {t(t('PROFILE') + ' ' + affiliation.name + ' ' + t('NONACTIVE_TWO'))}</Button>);
        }
        else return (<Button variant="primary" style={{marginRight: 10, marginBottom: 20, background:"#00494c"}} onClick={membershipStandBy('ReplayerTV')}><img src={`${clockIcon}`} style={{width:"25px"}}/> {t('MEMBERSHIP_RTV_STAND_BY')} {rtvChannel ? rtvChannel.name : null}</Button>);
      } else {
        if (s.status === subscriptionStatuses.unlimited && rtvChannel && rtvChannel.type === 0) {
          return (<Button variant="dark" style={{marginRight: 10, marginBottom: 20}} onClick={() => rtvMembershipNotAllowed()}><img src={`${offIcon}`} style={{width:"25px"}}/> {t('MEMBERSHIP_RTV')} {rtvChannel ? rtvChannel.name : null}</Button>);
        }
        else if (rtvChannel && !rtvChannel.blocked)
        {
          return (<Button variant="primary" style={{marginRight: 10, marginBottom: 20, background:"#00c1bd", opacity: 0.65}} onClick={() => addToGroupConfirmNoPayment(affiliation, 'ReplayerTV', t('MEMBERSHIP_RTV_DESC'))}><img src={`${onOffIcon}`} style={{width:"25px"}}/> {t('MEMBERSHIP_RTV')} {rtvChannel ? rtvChannel.name : null}</Button>);
        }
      }
    }
  }

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear'}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter dashboard_hero_rp_sport_specialist" data-parallax="true"/>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-12 mb-sm-2 mb-md-4">
                    <div className="detail_head_rp rounded" style={{backgroundColor: '#2f0e6d'}}>
                      <h4 className="section_title_neg_rp">{partner.businessName}</h4>
                      {
                        isFavourite ?
                          <div className="favorites_card_heart_btn favorites_card_heart_btn_active" onClick={toggleFavourite(partner.uid, isFavourite)}/> :
                          <div className="favorites_card_heart_btn" onClick={toggleFavourite(partner.uid, isFavourite)}/>
                      }
                      <hr className="hr_blue"/>
                      <ul className="list-unstyled list-inline row">
                        <li className="list-inline-item col-lg-4 mb-1"><img src={pointerIcon} alt={t('ICON_POINTER')}/>{partner.city}, {partner.address}</li>
                        <li className="list-inline-item col-lg-4 mb-1"><img src={envelopeIcon} alt={t('ICON_EMAIL')}/><a href={`mailto:${partner.user.email}`}>{partner.user.email}</a></li>
                        <li className="list-inline-item col-lg-4 mb-1"><img src={phoneIcon} alt={t('ICON_PHONE')}/><a href={`tel:${partner.phoneNumber}`}>{partner.phoneNumber}</a></li>
                      </ul>
                      <hr className="hr_blue"/>
                      <div className="row">
                        <div className="col-lg-6 mt-sm-2 mt-lg-4 mb-sm-2 mb-lg-45">
                          <h5 className="section_title_neg_rp">{t('BRIEF_DESCRIPTION')}</h5>
                          <p>{partner.description}</p>
                        </div>
                        <div className="col-lg-6 mt-sm-2 mt-lg-4 mb-sm-2 mb-lg-4">
                          <h5 className="section_title_neg_rp">{t('SERVICES')}</h5>
                          <ul className="card_facilities_list_icons mb-5">
                            {
                              partner.rtvChannels &&  partner.rtvChannels.length > 0 ?
                              <li className="card_facilities_list_icons_item"><img src={rtvIcon} alt={t('REPLAYER_TV')} title={t('REPLAYER_TV')} style={{width: '20px'}}/></li> :
                                null
                            }
                            {
                              partner.facilities && partner.facilities.parking ?
                                <li className="card_facilities_list_icons_item"><img src={parking} alt={t('PARK')} title={t('PARK')} style={{width: '20px'}}/></li> :
                                null
                            }
                            {
                              partner.facilities && partner.facilities.lockerRooms ?
                                <li className="card_facilities_list_icons_item"><img src={lockerRooms} alt={t('LOCKER_ROOMS')} title={t('LOCKER_ROOMS')} style={{width: '20px'}}/></li> :
                                null
                            }
                            {
                              partner.facilities && partner.facilities.showers ?
                                <li className="card_facilities_list_icons_item"><img src={showers} alt={t('SHOWERS')} title={t('SHOWERS')} style={{width: '20px'}}/></li> :
                                null
                            }
                            {
                              partner.facilities && partner.facilities.restaurant ?
                                <li className="card_facilities_list_icons_item"><img src={restaurant} alt={t('RESTAURANT_PIZZERIA')} title={t('RESTAURANT_PIZZERIA')} style={{width: '20px'}}/></li> :
                                null
                            }
                            {
                              partner.facilities && partner.facilities.bar ?
                                <li className="card_facilities_list_icons_item"><img src={bar} alt={t('BAR_PUB')} title={t('BAR_PUB')} style={{width: '20px'}}/></li> :
                                null
                            }
                            {
                              partner.facilities && partner.facilities.shop ?
                                <li className="card_facilities_list_icons_item"><img src={shop} alt={t('SHOP_RENTAL')} title={t('SHOP_RENTAL')} style={{width: '20px'}}/></li> :
                                null
                            }
                            {
                              partner.facilities && partner.facilities.picnic ?
                                <li className="card_facilities_list_icons_item"><img src={picnic} alt={t('PARTY_PICNIC')} title={t('PARTY_PICNIC')} style={{width: '20px'}}/></li> :
                                null
                            }
                            {
                              partner.facilities && partner.facilities.tournaments ?
                                <li className="card_facilities_list_icons_item"><img src={tournaments} alt={t('TOURNAMENTS')} title={t('TOURNAMENTS')} style={{width: '20px'}}/></li> :
                                null
                            }
                            {
                              partner.facilities && partner.facilities.petFriendly ?
                                <li className="card_facilities_list_icons_item"><img src={petFriendly} alt={t('PET_FRIENDLY')} title={t('PET_FRIENDLY')} style={{width: '20px'}}/></li> :
                                null
                            }
                            {
                              partner.facilities && partner.facilities.accessibility ?
                                <li className="card_facilities_list_icons_item"><img src={accessibility} alt={t('DISABLED_ACCESS')} title={t('DISABLED_ACCESS')} style={{width: '20px'}}/></li> :
                                null
                            }
                            {
                              partner.facilities && partner.facilities.relaxArea ?
                                <li className="card_facilities_list_icons_item"><img src={relax} alt={t('RELAX_AREA')} title={t('RELAX_AREA')} style={{width: '20px'}}/></li> :
                                null
                            }
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        {
                          partner.openingHour && partner.openingHour.sportsCenter ?
                            <div className="col-lg-6 mt-sm-2 mb-sm-2 mb-lg-4">
                              <h5 className="section_title_neg_rp">{t('SPORTS_CENTER_HOURS')}</h5>
                              <ul className="list-unstyled">
                                {partner.openingHour.sportsCenter.split('\n').map(t => <li>{t}</li>)}
                              </ul>
                            </div> :
                            null
                        }
                        {
                          partner.openingHour && partner.openingHour.secretary ?
                            <div className="col-lg-6 mt-sm-2 mb-sm-2 mb-lg-4">
                              <h5 className="section_title_neg_rp">{t('SECRETARIAT_HOURS')}</h5>
                              <ul className="list-unstyled">
                                {partner.openingHour.secretary.split('\n').map(t => <li>{t}</li>)}
                              </ul>
                            </div> :
                            null
                        }
                      </div>
                      <hr className="hr_blue"/>
                      <div className="row memberhip_rp">
                        <div className="col-12">
                          <h5 className="section_title_neg_rp">{t('MEMBERSHIPS')}</h5>
                        </div>
                        <div className="col-12">
                          {
                            partner.affiliations.length === 0 ?
                              <span className="lightblue_text ">{t('SPORTS_MEMBER_PROFILE')}</span> :
                              partner.affiliations.filter(a => a.active).map(g => renderMembershipButton(g))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col>
                    <h4 className="section_title_pos_rp" style={{marginTop: 40, marginBottom: 30}}>{t('SERVICES')}</h4>
                  </Col>
                </Row>
                <div className="row">
                  {
                    services.map(s =>
                      (
                        <div key={s.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                          <Card style={{backgroundColor: '#2f0e6d'}}>
                            {
                              s.imagesNames.length === 0 ?
                                <Fragment>
                                  <div className="card sportspecialist_card_bg_rp">
                                    <div className="card_image_rp">
                                      <div className="videoWrapper card_image_sportcenter_rp"/>
                                    </div>
                                  </div> 
                                </Fragment> :
                                <Carousel style={{marginBottom: 10}} interval={null}>
                                  {
                                    s.imagesNames.map(i =>
                                      (
                                        <Carousel.Item key={i}>
                                          <img
                                            className="d-block w-100"
                                            src={`${Configuration.apiEndPoint}/partners/services/${s.id}/images?load=${i}`}
                                            alt={t('FIRST_SLIDE')}
                                            style={{width: '100%'}}
                                          />
                                        </Carousel.Item>
                                      )
                                    )
                                  }
                                </Carousel>
                            }
                            <div className="card-body d-flex flex-column card_border_bottom_white mb-3">
                              <div className="d-flex">
                                <div>
                                  <h5 className="card-title">{s.name}</h5>
                                  <p>{s.briefDescription}</p>
                                </div>
                              </div>
                              <p>{s.description}</p>
                            </div>
                            <div className="text-center mt-auto mb-3">
                            {
                              s.recording ?
                                <a href="#" className="btn btn_red_rp mb-2 ml-1 mr-1" onClick={showServiceVideoStore(partner.uid, s.id)}>{t('VIDEOS')}</a> :
                                null
                            }
                              <a href="#" className="btn btn_red_outline_rp mb-2 ml-1 mr-1" onClick={showDetail(s)}>{t('INFORMATION')}</a>
                              <a href="#" className="btn btn_blue_rp mb-2 ml-1 mr-1" onClick={showServiceCalendar(partner.uid, s.id)}>{s.bookable ? t('BOOK') : t('CALENDAR')}</a>
                            </div>
                          </Card>
                        </div>
                      )
                    )
                  }
                </div>
                <Row>
                  <Col>
                    {fields.length > 0 ? <h4 className="section_title_pos_rp" style={{marginTop: 20, marginBottom: 30}}>{t('FIELDS')}</h4> : null}
                  </Col>
                </Row>
                <div className="row">
                  {
                    fields.map(f =>
                      (
                        <div key={f.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                          <Card style={{backgroundColor: '#2f0e6d'}}>
                            {
                              f.imagesNames.length === 0 ?
                                <Fragment>
                                  <div className="card sportspecialist_card_bg_rp">
                                    <div className="card_image_rp">
                                      <div className="videoWrapper card_image_sportcenter_rp"/>
                                    </div>
                                  </div> 
                                </Fragment> :
                                <Carousel style={{marginBottom: 10}} interval={null}>
                                  {
                                    f.imagesNames.map(i =>
                                      (
                                        <Carousel.Item key={i}>
                                          <img
                                            className="d-block w-100"
                                            src={`${Configuration.apiEndPoint}/partners/fields/${f.id}/images?load=${i}`}
                                            alt={t('FIRST_SLIDE')}
                                            style={{width: '100%'}}
                                          />
                                        </Carousel.Item>
                                      )
                                    )
                                  }
                                </Carousel>
                            }
                            <div className="card-body d-flex flex-column card_border_bottom_white mb-3">
                              <div className="d-flex">
                                <div className="sportcenter_rp_title_icon">
                                  <img src={SPORTS[f.sport] ? SPORTS[f.sport].icon : null} alt={t('ICON_SPORT')}/>
                                </div>
                                <div>
                                  <h5 className="card-title">{f.name}</h5>
                                  <p>{f.sport}</p>
                                </div>
                              </div>
                              <p>{f.description}</p>
                              <ul className="card_facilities_list_icons">
                                {
                                  f.covered ?
                                    <li className="card_facilities_list_icons_item"><img src={`${indoorIcon}`} alt={t('ICON_OUTDOOR')}/> {t('COVERED_FIELD')}</li> :
                                    <li className="card_facilities_list_icons_item"><img src={`${outdoorIcon}`} alt={t('ICON_OUTDOOR')}/> {t('OPEN_FIELD')}</li>
                                }
                                {
                                  f.terrain === 'Piscina' ?
                                    <li className="card_facilities_list_icons_item pd-lg-4"><img src={`${swimmingPoolIcon}`} alt={t('ICON_TERRAIN')}/>{f.terrain}</li> :
                                    <li className="card_facilities_list_icons_item pd-lg-4"><img src={`${terrainIcon}`} alt={t('ICON_TERRAIN')}/>{f.terrain}</li>
                                }
                              </ul>
                            </div>
                            <div className="text-center mt-auto mb-3">
                            {
                              f.recording ?
                                <a href="#" className="btn btn_red_rp mb-2 ml-1 mr-1" onClick={showVideoStore(partner.uid, f.id)}>{t('VIDEOS')}</a> :
                                null
                            }
                              <a href="#" className="btn btn_red_outline_rp mb-2 ml-1 mr-1" onClick={showDetail(f)}>{t('INFORMATION')}</a>
                              <a href="#" className="btn btn_blue_rp mb-2 ml-1 mr-1" onClick={showCalendar(partner.uid, f.id)}>{f.bookable ? t('BOOK') : t('CALENDAR')}</a>
                            </div>
                          </Card>
                        </div>
                      )
                    )
                  }
                </div>
                <Row>
                  <Col>
                    <h4 className="section_title_pos_rp" style={{marginTop: 20, marginBottom: 30}}>{t('SPECIAL_EVENTS')}</h4>
                    {
                      events.length === 0 ?
                        <h6 className="section_title_pos_rp" style={{marginTop: 20, marginBottom: 30}}>{t('NO_SPECIAL_EVENTS')}</h6> : null
                    }
                  </Col>
                </Row>
                <Row>
                {
                  events.map(e =>
                    (
                      <Col key={e.id} xs={12} sm={12} md={12} xl={6} lg={6}>
                        <Card style={{marginBottom: 10}}>
                          <Card.Body style={{backgroundColor: '#2f0e6d'}}>
                          {
                            e.imageName.length === 0 ?
                              <Fragment>
                                <div className="card event_card_bg_rp">
                                  <div className="card_image_rp">
                                    <div className="videoWrapper card_image_sportcenter_rp"/>
                                </div>
                              </div> 
                              </Fragment> :
                              <Carousel style={{marginBottom: 10}} interval={null}>
                                {
                                  e.imageName.map(i =>
                                    (
                                      <Carousel.Item key={i}>
                                        <img
                                          className="d-block w-100"
                                          src={`${Configuration.apiEndPoint}/partners/events/${e.id}/images?load=${i}`}
                                          alt={t('FIRST_SLIDE')}
                                          style={{width: '100%'}}
                                        />
                                      </Carousel.Item>
                                    )
                                  )
                                }
                              </Carousel>
                            }
                            <Card.Title style={{marginTop: 30}}>
                              {e.title}
                            </Card.Title>
                            <Card.Text>
                              {e.description}
                            </Card.Text>
                            <Card.Text>
                             {t('SPECIAL_EVENT_DATES', {start_date: new Date(e.startDatetime).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'}), end_date: new Date(e.endDatetime).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})}
                            </Card.Text>
                            {
                              partner.recording ?
                                <div className="d-flex favorites_card_badge mb-3">
                                  <img src={camIcon} className="favorites_card_badge_icon" alt={t('ICON_FAVORITES')}/>
                                  <p>{t('HD_VIDEO_SERVICE')}</p>
                                  {
                                    partner.rtvChannels && partner.rtvChannels.length > 0 ?
                                      <a href="#" className="btn btn_red_rp ml-auto" onClick={showReplayerTV(e.rtvChannelId, partner, getSubscriptionStatusEvent(e.uid, e.rtvChannelId))}><img src={replayerTvIcon}/></a> :
                                      null
                                  }
                                </div> :
                                null
                            }
                          </Card.Body>
                        </Card>
                      </Col>
                    )
                  )
                }
              </Row>
              </div>
            </div>
            <div className="section bg_lightgrey_rp mt-sm-2 mt-lg-4">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3 d-flex align-items-center justify-content-center">
                    <img src={calendarIconRed} className="d-block" alt={t('ICON_CALENDAR')}/>
                  </div>
                  <div className="col-sm-9 section_text_pos_rp p-4">
                    <h4 className="section_title_pos_rp">{t('SPORTS_SPECIALIST_CALENDAR')}</h4>
                    <p>{t('SPORTS_SPECIALIST_CALENDAR_DESC')}</p>
                    <a href="#" className="btn btn_blue_rp mb-2" onClick={showCalendar(partner.uid)}>{t('BROWSE_AND_BOOK')}</a>
                  </div>
                </div>
              </div>
            </div>
            {partner.rtvChannels && partner.rtvChannels.length > 0 ?
            <div className="section bg_lightgrey_rp sportcenter_replayertv_section p-4">
              <div className="container bg_two_players_rp rounded">
                <div className="row">
                  <div className="col-sm-3 d-flex align-items-center justify-content-center">
                    <img src={replayerTvRed} className="d-block replayer_tv_icon" alt={t('ICON_REPLAYER_TV')}/>
                  </div>
                  <div className="col-sm-9 p-4">
                    <h4 className="section_title_neg_rp"><a name="replayer_tv_section">{t('DISCOVER_OUR_TV_CHANNEL')}</a></h4>
                    <p>{t('DISCOVER_OUR_TV_CHANNEL_DESC')}</p>
                    <a href="#" className="btn btn_red_rp mb-2" onClick={chooseReplayerTV(partner)}>{t('REPLAYER_TV')}</a>
                  </div>
                </div>
              </div>
            </div>
            : null}
            <div className="section bg_lightgrey_rp sportcenter_gallery_section">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6 section_text_pos_rp p-4 d-flex align-items-center">
                    <div>
                      <h4 className="section_title_pos_rp">{partner.name}</h4>
                      <p>
                        {partner.description || ''}
                      </p>
                      <p>
                        {partner.extDescription || ''}
                      </p>
                      {
                        partner.youtube || partner.facebook || partner.instagram ?
                          <h5 className="section_title_pos_rp">{t('OUR_SOCIAL_NETWORKS')}</h5> :
                          null
                      }
                      <ul className="list-unstyled list-inline">
                        {
                          partner.youtube ?
                            <li className="list-inline-item"><a href={partner.youtube} target='_blank'><img src={youtubePosIcon} className="sportcenter_social_icon" alt={t('ICON_YOUTUBE')}/></a></li> :
                            null
                        }
                        {
                          partner.facebook ?
                            <li className="list-inline-item"><a href={partner.facebook} target='_blank'><img src={facebookPosIcon} className="sportcenter_social_icon" alt={t('ICON_FACEBOOK')}/></a></li> :
                            null
                        }
                        {
                          partner.instagram ?
                            <li className="list-inline-item"><a href={partner.instagram} target='_blank'><img src={instagramPosIcon} className="sportcenter_social_icon" alt={t('ICON_INSTAGRAM')}/></a></li> :
                            null
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 section_text_pos_rp p-4">
                    {
                      partner.imagesNames.length === 0 ?
                        null :
                        <Carousel style={{marginBottom: 10}} interval={null}>
                          {
                            partner.imagesNames.map(i =>
                              (
                                <Carousel.Item key={i}>
                                  <img
                                    className="d-block w-100"
                                    src={`${Configuration.apiEndPoint}/images/${partner.uid}?load=${i}`}
                                    alt={t('FIRST_SLIDE')}
                                    style={{width: '100%'}}
                                  />
                                </Carousel.Item>
                              )
                            )
                          }
                        </Carousel>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PlayerNewSubscription/>
          <PlayerNewEventSubscription/>
          <PlayerInsufficientFunds/>
          <PlayerChooseRtvChannel/>
        </Container>
      </Fade>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

ShowPartnerSpecialist.propTypes = {
  partner: PropTypes.object,
  isFavourite: PropTypes.bool,
  token: PropTypes.string,
  fields: PropTypes.array,
  services: PropTypes.array,
  showCalendar: PropTypes.func.isRequired,
  showServiceCalendar: PropTypes.func.isRequired,
  showDetail: PropTypes.func.isRequired,
  toggleFavourite: PropTypes.func.isRequired,
  showReplayerTV: PropTypes.func.isRequired,
  showVideoStore: PropTypes.func.isRequired,
  showServiceVideoStore: PropTypes.func.isRequired,
  rtvMembershipNotAllowed: PropTypes.func.isRequired,
  addToCoachNotAllowed: PropTypes.func.isRequired,
  subscriptions: PropTypes.object,
  events: PropTypes.array,
  chooseReplayerTV: PropTypes.func.isRequired
};

ShowPartnerSpecialist.defaultProps = {
  partner: null,
  isFavourite: false,
  token: null,
  fields: [],
  services: [],
  subscriptions: null,
  events: [],
  affiliations: []
};

export default ShowPartnerSpecialist;