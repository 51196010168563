const defaultState = {
  ownedEvents: [],
  ownedEvent: null,
  ownedFields: [],
  ownedField: null,
  ownedFieldSlots: [],
  ownedFieldSlot: null,
  ownedServices: [],
  ownedService: null,
  isSlotModalVisible: false,
  isBookingModalVisible: false,
  ownedFieldBookings: [],
  ownedFieldBooking: null,
  bookingSlots: null,
  partnerFieldsActiveTab: 'campi',
  ownedRtvChannels: [],
  ownedRtvChannel: null,
  videosStore: [],
  videosStoreSoon: [],
  rtvVideos: [],
  rtvVideosSoon: [],
  permanentVideos: [],
  storagePrices: {},
  selectedPermanentVideo: null,
  isVideoHighlightModalVisible: false,
  userTags: [],
  myVideoActiveTab: 'video',
  selectedVideoForTagging: null,
  taggedUserVideos: [],
  isStorageModalVisible: false,
  playlists: [],
  userVideoTags: [],
  isUserTagModalVisible: false,
  isVideoSharingModalVisible: false,
  selectedUserTag: null,
  isUserVideoTagModalVisible: false,
  selectedUserVideoTag: null,
  isCameraModalVisible: false,
  exportHighlightOrPlaylist : null,
  selectedUserVideoTags: [],
  isPartnerVideoCreationVisible: false,
  partnerVideos: [],
  selectedPartnerVideo: null,
  isPartnerVideoEditModalVisible: false,
  isPlayersModalVisible: false,
  playersList: [],
  rtvCategories: [],
  selectedCategory: {},
  isFixedCostsModalVisible: false,
  isAffiliationModalVisible: false,
  affiliations: [],
  selectedAffiliation: null,
  affiliatedPlayers: [],
  partnerAffiliationsActiveTab: 'affiliation_requests',
};

const defaultEvent = {
  id: null,
  title: null,
  description: null,
  startDatetime: null,
  endDatetime: null
}

const defaultField = {
  id: null,
  sport: null,
  name: null,
  description: null,
  covered: false,
  terrain: null,
  recording: false,
  payment: false,
  additionalCost: null,
  bookingPolicy: null,
  termOfUse: null,
  imagesNames: [],
  bookable: null,
  unbookableHoours: null,
  affiliations: []
};

const defaultService = {
  id: null,
  briefDescription: null,
  name: null,
  description: null,
  recording: false,
  payment: false,
  additionalCost: null,
  bookingPolicy: null,
  termOfUse: null,
  imagesNames: [],
  bookable: null,
  unbookableHours: null,
  affiliations: []
};

const defaultSlot = {
  id: null,
  fieldId: null,
  serviceId: null,
  startDatetime: null,
  endDatetime: null,
  state: null,
  price: null,
  visibility: false,
  groupId: null,
  rtvChannels: [],
  minimumPlayers: null,
  public: null,
};

const defaultBooking = {

};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'OWNED_EVENTS_RECEIVED': {
      return Object.assign({}, state, {ownedEvents: action.ownedEvents});
    }

    case 'NEW_OWNED_EVENT': {
      return Object.assign({}, state, {ownedEvent: defaultEvent}, {...action.initialValues});
    }

    case 'OWNED_EVENT_SELECTED': {
      return Object.assign({}, state, {ownedEvent: action.ownedEvent});
    }

    case 'OWNED_FIELDS_RECEIVED': {
      return Object.assign({}, state, {ownedFields: action.ownedFields});
    }

    case 'NEW_OWNED_FIELD': {
      return Object.assign({}, state, {ownedField: defaultField}, {...action.initialValues});
    }

    case 'NEW_OWNED_SERVICE': {
      return Object.assign({}, state, {ownedService: defaultService}, {...action.initialValues});
    }

    case 'OWNED_FIELD_SELECTED': {
      return Object.assign({}, state, {ownedField: action.ownedField});
    }

    case 'OWNED_FIELD_SLOTS_RECEIVED': {
      return Object.assign({}, state, {ownedFieldSlots: action.ownedFieldSlots});
    }

    case 'OWNED_SERVICES_RECEIVED': {
      return Object.assign({}, state, {ownedServices: action.ownedServices});
    }

    case 'OWNED_SERVICE_SELECTED': {
      return Object.assign({}, state, {ownedService: action.ownedService});
    }

    case 'SHOW_SLOT_MODAL': {
      return Object.assign({}, state, {isSlotModalVisible: true});
    }

    case 'HIDE_SLOT_MODAL': {
      return Object.assign({}, state, {isSlotModalVisible: false});
    }

    case 'NEW_OWNED_FIELD_SLOT': {
      return Object.assign({}, state, {ownedFieldSlot: Object.assign(defaultSlot, {...action.initialValues})});
    }

    case 'OWNED_FIELD_SLOT_SELECTED': {
      return Object.assign({}, state, {ownedFieldSlot: action.ownedFieldSlot, bookingSlots: null});
    }

    case 'OWNED_FIELD_BOOKINGS_RECEIVED': {
      return Object.assign({}, state, {ownedFieldBookings: action.ownedFieldBookings});
    }

    case 'SHOW_BOOKING_MODAL': {
      return Object.assign({}, state, {isBookingModalVisible: true});
    }

    case 'HIDE_BOOKING_MODAL': {
      return Object.assign({}, state, {isBookingModalVisible: false});
    }

    case 'NEW_OWNED_FIELD_BOOKING': {
      return Object.assign({}, state, {ownedFieldBooking: Object.assign(defaultBooking, {...action.initialValues})});
    }

    case 'OWNED_FIELD_BOOKING_SELECTED': {
      return Object.assign({}, state, {ownedFieldBooking: action.ownedFieldBooking});
    }

    case 'PARTNER_BOOKING_MULTI_SLOT_SELECTED': {
      return Object.assign({}, state, {bookingSlots: action.bookingSlots, ownedFieldSlot: null});
    }

    case 'PARTNER_FIELDS_ACTIVE_TAB': {
      return Object.assign({}, state, {partnerFieldsActiveTab: action.activeTab});
    }

    case 'OWNED_RTV_CHANNELS_RECEIVED': {
      return Object.assign({}, state, {ownedRtvChannels: action.ownedRtvChannels});
    }

    case 'OWNED_RTV_CHANNEL_SELECTED': {
      return Object.assign({}, state, {ownedRtvChannel: action.ownedRtvChannel});
    }

    case 'PARTNER_VIDEOS_STORE_RECEIVED': {
      return Object.assign({}, state, {videosStore: action.videosStore, videosStoreSoon: action.videosStoreSoon});
    }

    case 'PARTNER_RTV_VIDEOS_RECEIVED': {
      return Object.assign({}, state, {rtvVideos: action.rtvVideos, rtvVideosSoon: action.rtvVideosSoon});
    }

    case 'PARTNER_PERMANENT_VIDEOS_RECEIVED': {
      return Object.assign({}, state, {permanentVideos: action.videos});
    }

    case 'PARTNER_STORAGE_PRICES_RECEIVED': {
      return Object.assign({}, state, {storagePrices: action.prices});
    }

    case 'PARTNER_PERMANENT_VIDEO_SELECTED': {
      return Object.assign({}, state, {selectedPermanentVideo: action.selectedPermanentVideo});
    }

    case 'PARTNER_SHOW_VIDEO_HIGHLIGHT_MODAL': {
      return Object.assign({}, state, {isVideoHighlightModalVisible: true});
    }

    case 'PARTNER_HIDE_VIDEO_HIGHLIGHT_MODAL': {
      return Object.assign({}, state, {isVideoHighlightModalVisible: false});
    }

    case 'PARTNER_USER_TAGS_RECEIVED': {
      return Object.assign({}, state, {userTags: action.userTags});
    }

    case 'PARTNER_MY_VIDEO_ACTIVE_TAB': {
      return Object.assign({}, state, {myVideoActiveTab: action.activeTab});
    }

    case 'PARTNER_VIDEO_FOR_TAGGING_SELECTED': {
      return Object.assign({}, state, {selectedVideoForTagging: action.video});
    }

    case 'PARTNER_TAGGED_USER_VIDEOS_RECEIVED': {
      return Object.assign({}, state, {taggedUserVideos: action.taggedUserVideos});
    }

    case 'PARTNER_SHOW_STORAGE_MODAL': {
      return Object.assign({}, state, {isStorageModalVisible: true});
    }

    case 'PARTNER_HIDE_STORAGE_MODAL': {
      return Object.assign({}, state, {isStorageModalVisible: false});
    }

    case 'PARTNER_PLAYLISTS_RECEIVED': {
      return Object.assign({}, state, {playlists: action.playlists});
    }

    case 'PARTNER_USER_VIDEO_TAGS_RECEIVED': {
      return Object.assign({}, state, {userVideoTags: action.userVideoTags});
    }

    case 'PARTNER_SHOW_USER_TAG_MODAL': {
      return Object.assign({}, state, {isUserTagModalVisible: true});
    }

    case 'PARTNER_HIDE_USER_TAG_MODAL': {
      return Object.assign({}, state, {isUserTagModalVisible: false});
    }

    case 'PARTNER_SHOW_VIDEO_SHARING_MODAL': {
      return Object.assign({}, state, {isVideoSharingModalVisible: true});
    }

    case 'PARTNER_HIDE_VIDEO_SHARING_MODAL': {
      return Object.assign({}, state, {isVideoSharingModalVisible: false});
    }

    case 'PARTNER_NEW_USER_TAG': {
      return Object.assign({}, state, {selectedUserTag: {label: null, duration: 10, color: '#142e7d'}});
    }

    case 'PARTNER_USER_TAG_SELECTED': {
      return Object.assign({}, state, {selectedUserTag: action.selectedUserTag});
    }

    case 'PARTNER_SHOW_USER_VIDEO_TAG_MODAL': {
      return Object.assign({}, state, {isUserVideoTagModalVisible: true});
    }

    case 'PARTNER_HIDE_USER_VIDEO_TAG_MODAL': {
      return Object.assign({}, state, {isUserVideoTagModalVisible: false});
    }

    case 'PARTNER_NEW_USER_VIDEO_TAG': {
      return Object.assign({}, state, {selectedUserVideoTag: {userVideoId: action.videoId, userTagId: action.userTagId, startTime: action.startTime, stopTime: action.stopTime, description: null}});
    }

    case 'PARTNER_USER_VIDEO_TAG_SELECTED': {
      return Object.assign({}, state, {selectedUserVideoTag: action.selectedUserVideoTag});
    }

    case 'PARTNER_SHOW_CAMERA_MODAL': {
      return Object.assign({}, state, {isCameraModalVisible: true});
    }

    case 'PARTNER_HIDE_CAMERA_MODAL': {
      return Object.assign({}, state, {isCameraModalVisible: false});
    }

    case 'PARTNER_HIGHLIGHT_OR_PLAYLIST_SELECTED': {
      return Object.assign({}, state, {exportHighlightOrPlaylist: action.value})
    }

    case 'PARTNER_SET_SELECTED_USER_VIDEO_TAGS': {
      return Object.assign({}, state, {selectedUserVideoTags: action.tags})
    }

    case 'PARTNER_CLEAR_SELECTED_USER_VIDEO_TAGS': {
      return Object.assign({}, state, {selectedUserVideoTags: []})
    }

    case 'PARTNER_SHOW_VIDEO_CREATION_MODAL': {
      return Object.assign({}, state, {isPartnerVideoCreationVisible: true})
    }

    case 'PARTNER_HIDE_VIDEO_CREATION_MODAL': {
      return Object.assign({}, state, {isPartnerVideoCreationVisible: false})
    }

    case 'PARTNER_VIDEOS_RECEIVED': {
      return Object.assign({}, state, {partnerVideos: action.videos})
    }

    case 'SELECT_PARTNER_VIDEO': {
      return Object.assign({}, state, {selectedPartnerVideo: action.video, isPartnerVideoEditModalVisible: true})
    }

    case 'HIDE_PARTNER_VIDEO_EDIT_MODAL': {
      return Object.assign({}, state, {selectedPartnerVideo: null, isPartnerVideoEditModalVisible: false})
    }

    case 'PARTNER_SHOW_PLAYERS_MODAL': {
      return Object.assign({}, state, {isPlayersModalVisible: true});
    }

    case 'PARTNER_HIDE_PLAYERS_MODAL': {
      return Object.assign({}, state, {isPlayersModalVisible: false});
    }

    case 'PARTNER_PLAYERS_LIST_RECEIVED': {
      return Object.assign({}, state, {playersList: action.players});
    }

    case 'PARTNER_PLAYERS_CLEAN_LIST_RECEIVED': {
      return Object.assign({}, state, {playersList: []});
    }

    case 'PARTNER_RTV_CATEGORIES_RECEIVED': {
      return Object.assign({}, state, {rtvCategories: action.categories})
    }

    case 'PARTNER_SELECT_RTV_CATEGORY': {
      return Object.assign({}, state, {selectedCategory: action.category});
    }

    case 'PARTNER_OPEN_FIXED_COSTS_MODAL': {
      return Object.assign({}, state, {isFixedCostsModalVisible: true});
    }

    case 'PARTNER_CLOSE_FIXED_COSTS_MODAL': {
      return Object.assign({}, state, {isFixedCostsModalVisible: false});
    }

    case 'PARTNER_SHOW_AFFILIATION_MODAL': {
      return Object.assign({}, state, {isAffiliationModalVisible: true});
    }

    case 'PARTNER_HIDE_AFFILIATION_MODAL': {
      return Object.assign({}, state, {isAffiliationModalVisible: false});
    }

    case 'PARTNER_AFFILIATIONS_RECEIVED': {
      return Object.assign({}, state, {affiliations: action.affiliations});
    }

    case 'PARTNER_SELECT_AFFILIATION': {
      return Object.assign({}, state, {selectedAffiliation: action.affiliation});
    }

    case 'PARTNER_AFFILIATED_PLAYERS_RECEIVED': {
      return Object.assign({}, state, {affiliatedPlayers: action.players});
    }

    case 'PARTNER_AFFILIATIONS_ACTIVE_TAB': {
      return Object.assign({}, state, {partnerAffiliationsActiveTab: action.activeTab});
    }

    default:
      return state;
  }
};
